.troutcoin__container {
  display: inline-block;
}

.troutcoin {
  border-radius: 100%;
  border: 2px solid gold;
  width: 2.625rem;
  height: 2.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gold;
}

.troutcoin > svg {
  display: inline-block;
}

.troutcoin.troutcoin--spinning {
  animation-name: rotate;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
