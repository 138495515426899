.App {
  background-image: url('https://i.ibb.co/X7mLPY5/mobile-bg-WEB.jpg');
  background-size: cover;
  width: 100%;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App--loading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
}

@media all and (min-width: 1024px) {
  .App {
    background-image: url('https://i.ibb.co/7ncbzqC/bg-compressed-WEB.jpg');
  }
}
