.modal {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.175rem);
}

.modal__inner {
  width: 600px;
  max-width: 90vw;
  min-height: 222px;
  padding: 1rem;
  height: auto;
  max-height: 98%;
  overflow-y: auto;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 0.5rem 0.05rem #cecece;
  background-color: #464242;
  animation: fadeIn 0.5s;
}

.modal__content {
  flex-grow: 1;
}

.modal__close-button {
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0.1rem #ccc;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  display: inline-block;
}

@media all and (min-width: 768px) {
  .modal {
    align-items: flex-start;
    padding-top: 2rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
