.view {
  padding: 0.5rem 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

/* for tablets and desktops */
@media screen and (min-width: 768px) {
  .view {
    padding: 1rem 0;
  }
}
