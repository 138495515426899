.navbar {
  display: none;
  position: absolute;
  top: 3rem;
  left: 0;
  width: 100%;
  background: rgba(25, 25, 25, 0.7);
  z-index: 10;
  text-align: center;
}

.navbar--visible {
  display: block;
}

.navbar__link {
  display: inline-block;
  padding: 0.25rem 1rem;
  margin-right: 0.25rem;
  color: #e08e30;
  font-weight: bold;
}

.navbar__link:hover {
  opacity: 0.6;
}

.navbar__items {
  display: flex;
  flex-direction: column;
}

.nav-bar__profile {
  margin-left: 0;
}

.nav-bar__profile__profile-link {
  /* use nav bar items color */
  color: #e08e30;
}

.nav-bar__profile__profile-link:hover {
  opacity: 0.6;
}

.navbar__link--active {
  opacity: 0.75;
}

.nav-bar__profile__profile-link__text {
  font-size: 0.75rem;
  display: block;
}

.nav-bar__profile__trout-coins {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-bar__profile__trout-coins__icon {
  animation-name: wiggle;
  animation-duration: 7s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
}

.nav-bar__profile__trout-coins__count {
  font-weight: bold;
  padding: 0.25rem;
  font-style: italic;
}

/* hamburgerb */
.hamburger-container {
  display: block;
  top: 0;
  left: 0;
}

.hamburger__button {
  background: none;
  border: none;
  padding: 0.5rem;
  display: inline-block;
}
.hamburger__svg {
  display: block;
}

.hamburger__svg * {
  transition: all 0.25s;
}

.hamburger-container__active .hamburger__top {
  transform: rotate(45deg) translate(6px, -4px);
}
.hamburger-container__active .hamburger__middle {
  transform: rotate(-45deg) translate(-16px, 11px);
}
.hamburger-container__active .hamburger__bottom {
  display: none;
}

@media all and (min-width: 768px) {
  .navbar {
    display: flex;
    /* doesnt actually work?? */
    position: sticky;
    top: 0;
  }

  .hamburger-container {
    display: none;
  }

  .navbar__items {
    flex-direction: row;
  }

  .nav-bar__profile {
    margin-left: auto;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  10% {
    transform: rotate(45deg);
  }
  20% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
