/* Helper classes and generic site-wide styles */
* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.caps {
  text-transform: uppercase;
}

pre {
  color: white;
  overflow: scroll;
  width: 100vw;
}

.image {
  max-width: 100%;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.screenreader-only {
  display: none;
}

.link {
  font-weight: bold;
}

.display-none {
  display: none;
}

.uppercase {
  text-transform: uppercase;
}

.transition-all {
  transition: all 0.5s;
}
