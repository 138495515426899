.footer {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  padding: 2rem 0;
  box-sizing: border-box;
  display: block;
  text-align: center;
}

.footer .navbar__items {
  justify-content: center;
  margin-bottom: 1rem;
}

.footer .navbar__link {
  font-weight: normal;
}

.footer__navbar__link--active {
  opacity: 0.75;
}
