.social-links {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.social-links__list-item {
  list-style: none;
}

.social-links__list-item a {
  color: white;
  display: inline-block;
}

.social-links__list-item a:hover {
  opacity: 0.75;
}

.social-links__list-item__text {
  font-size: 0.825rem;
}
