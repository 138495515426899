.notification-panel {
  width: 222px;
  min-height: 69px;
  height: auto;
  border-radius: 0.5rem;
  position: fixed;
  top: 1rem;
  left: 1rem;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  box-shadow: 0 0 0.5rem 0.05rem #cecece;
}

.notification {
  flex-grow: 1;
  text-align: center;
}

.notification-panel {
  background-color: #5f5f5f;
  color: white;
}

.notification-panel--success {
  background-color: #0c8f00;
}

.notification-panel--error {
  background-color: #d64646;
}

.notification__close-button {
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0.1rem #ccc;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  display: inline-block;
}

@media all and (min-width: 768px) {
  .notification-panel {
    top: 3rem;
    left: 3rem;
  }
}
